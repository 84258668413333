"use strict";

document.addEventListener( 'DOMContentLoaded', function () {
    
    var bodyElement = document.body;
    bodyElement.classList.remove("js-disable");

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * menu and aside nav
    // *
    // *

    // Select the header and menu elements
    const header = document.querySelector(".js-header");
    const sidebar = document.querySelector(".js-sidebar");
    const aside = document.querySelector(".js-menu-aside");

    // Select the menu button and its text attributes
    const menuBtn = document.querySelector(".js-menu-btn");
    const menuBtnText = menuBtn.getAttribute("data-menu-text");
    const menuCloseText = menuBtn.getAttribute("data-close-text");
    const logoLight = document.querySelector(".header__logoLight");
    const logoDark = document.querySelector(".header__logoDark");

    // Add a click event listener to the menu button
    menuBtn.addEventListener("click", function() {
        // If the menu is closed, open it and change button text
        if (menuBtn.innerText === menuBtnText) {
            menuBtn.innerText = menuCloseText;
            menuBtn.classList.add("close");
            aside.classList.add("open");
            document.body.style.overflow = "hidden";

            // If the page is in hero mode, remove the white background
            if (document.body.classList.contains('isHero')) {
                document.body.classList.remove("white");
            }
        } else {
            // If the menu is open, close it and change button text
            menuBtn.innerText = menuBtnText;
            menuBtn.classList.remove("close");
            aside.classList.remove("open");
            document.body.style.overflow = "auto";

            // If the page is in hero mode and the user hasn't scrolled too far down, add the white background
            if (document.body.classList.contains('isHero') && window.scrollY < 400) {
                document.body.classList.add("white");
            }
        }
    });

    // Add a keydown event listener to the document to handle the Escape key
    document.addEventListener('keydown', function(event) {
        if (event.key === "Escape" && aside.classList.contains('open')) {
            event.preventDefault();
            menuBtn.innerText = menuBtnText;
            menuBtn.classList.remove("close");
            aside.classList.remove('open'); 
            document.body.style.overflow = 'auto';
        }
    });

    // Add a scroll event listener to the document to handle the header background color
    document.addEventListener('scroll', function(e) {
        if (window.scrollY > 50) {
            header.classList.add('scroll');
            sidebar.classList.add('scroll');

            // If the page is in hero mode, remove the white background
            if (document.body.classList.contains('isHero')) {
                document.body.classList.remove("white");
                logoDark.style.display = 'block';
                logoLight.style.display = 'none';
            }
        } else {
            header.classList.remove('scroll');
            sidebar.classList.remove('scroll');

            // If the page is in hero mode, add the white background
            if (document.body.classList.contains('isHero')) {
                document.body.classList.add("white");
                logoDark.style.display = 'none';
                logoLight.style.display = 'block';
            }
        }
    });

    if (document.body.classList.contains('isHero')) {
        document.body.classList.add("white");
        logoDark.style.display = 'none';
        logoLight.style.display = 'block';
    }
    


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * subnav  menu
    // *
    // *

    // Select all elements with class "js-subnav"
    const nav = document.querySelectorAll(".js-subnav");

    // Check if any elements with class "js-subnav" were found
    if (nav !== null) {

        // Loop through each element with class "js-subnav"
        for (var i=0; i < nav.length; i++) {

            // Add an onclick event listener to the element
            nav[i].onclick = function(e) {

                // Toggle the "active" class on the parent element's parent element
                // This assumes that the parent element of the "js-subnav" element has a "parent" element that needs to be toggled
                this.parentNode.parentNode.classList.toggle("active");
            }
        }
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * * 
    // * splide heroSlider
    // *
    // *

    // Select the heroSlider
    const heroSlider = document.getElementsByClassName('js-hero-slider');
    
    // Check if heroSlider were found
    if (heroSlider !== null) {

        // Loop through each element with class "js-hero-slider'"
        for (let i = 0; i < heroSlider.length; i++) {
            new Splide(heroSlider[i], {
                type            : 'fade',
                pauseOnHover    : true,
                rewind          : true,
                lazyLoad        : 'nearby'
            }).mount();
        }
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * * 
    // * splide imageSlider
    // *
    // *

    // Select the imageSlider
    const imageSlider = document.getElementsByClassName('js-image-slider');
    
    // Check if imageSlider were found
    if (imageSlider !== null) {

        // Loop through each element with class "js-image-slider'"
        for (let i = 0; i < imageSlider.length; i++) {
            new Splide(imageSlider[i], {
                type            : 'fade',
                pauseOnHover    : true,
                rewind          : true,
                lazyLoad        : 'nearby'
            }).mount();
        }
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * splide textSlider
    // *
    // *

    // Select the textSlider
    const textSlider = document.getElementsByClassName('js-text-slider');
    
    // Check if imageSlider were found
    if (textSlider !== null) {
        for (let i = 0; i < textSlider.length; i++) {
            new Splide(textSlider[i], {
                type            : 'slide',
                rewind          : true,
                arrows          : true,
                pagination      : true,
                lazyLoad        : 'nearby',
                perPage         : 1,
                gap             : '3rem',
                breakpoints: {
                    768: {
                        perPage: 1,
                    }
                }
            }).mount();
        }
    }


    
    // * * * * * * * * * * * * * * * * * * * * * * * * * 
    // * hero video slider
    // *
    // *

    // Select the videoSlider
    const videoSlider = document.querySelector(".js-video-slider");
    
    // Check if imageSlider were found
    if (videoSlider !== null) {

        // splide init
        const splide = new Splide('.js-video-slider', {
            cover           : true,
            //heightRatio     : 0.5525,
            width           : '100vw',
		    height          : '100vh',
            arrows          : true,
            pagination      : false,
            video: {
                loop        : true,
                mute        : true,
                autoplay    : true
            }
        });

        //add Video Extension
        splide.mount(window.splide.Extensions);
    };



    // * * * * * * * * * * * * * * * * * * * * * * * * * 
    // * splide instagram
    // *
    // *
    
    // Select the instagramSlider
    const instagramSlider = document.querySelectorAll(".js-instagram-slider");

    // Check if imageSlider were found
    if (instagramSlider !== null) {
        for (let i = 0; i < instagramSlider.length; i++) {
            // splide init
            const splide = new Splide(instagramSlider[i], {
                type            : 'loop',
                rewind          : true,
                autoplay        : true,
                arrows          : false,
                pagination      : false,
                lazyLoad        : 'nearby',
                perPage         : 2,
                perMove         : 1,
                speed           : 1000,
                interval        : 2000,
                gap             : '2rem',
                padding: { left: 0, right: '25%' },
                breakpoints: {
                    768: {
                        perMove : 1,
                        perPage: 2,
                    },
                    576: {
                        perMove : 1,
                        perPage : 1,
                        padding : '30px'
                    },
                }
            }).mount();
        }
    };

    

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * scrollTo
    // *
    // *

    // Select all elements with the class "js-scrollTo" and store them in a variable called anchors
    const anchors = document.querySelectorAll(".js-scrollTo");

    // Iterate over each anchor and add a click event listener to it, calling the function clickHandler when clicked
    for (const anchor of anchors) {
        anchor.addEventListener("click", clickHandler);
    }

    // Function that is called when an anchor is clicked
    function clickHandler(e) {
        e.preventDefault();
        
        // Extract the ID of the target element from the anchor's href attribute and create a new ID by prepending "my" to it
        let href = this.getAttribute("href").split("#");
        let newAnchor = "#my" + href[1]
        
        // Get the offsetTop of the target element (i.e. how far it is from the top of the page)
        let offsetTop = document.querySelector(newAnchor).offsetTop;

        // Scroll to the target element using smooth scrolling behavior
        scroll({
            top: offsetTop,
            behavior: "smooth"
        });
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * hash link jump to content
    // *
    // *

    window.addEventListener('load', function () {
        let getHash = window.location.hash;
        let elementExists = document.getElementById("my" + getHash.slice(1));

        if (getHash !== null && elementExists !== null) {
            let name = "#my" + getHash.slice(1);
            let offsetTop = document.querySelector(name).offsetTop - 60;

            scroll({
                top: offsetTop,
                behavior: "smooth"
            });
        }

    }, false);



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * scrollToTop
    // *
    // *
    
    const scrolltotop  = document.querySelector(".js-scrolltop");

    if (scrolltotop !== null) {
        // show and hide scrollToTop btn on scroll
        document.addEventListener('scroll', function(e) {
            if (window.scrollY > 300) {
                scrolltotop.classList.add("show");
            } else {
                scrolltotop.classList.remove("show");
            }
        });

        // onclick scroll to top
        scrolltotop.onclick = function(e){
            e.preventDefault();
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }

        // onkeydown scroll to top or bottom
        scrolltotop.addEventListener("keydown", function (event) {
            if (event.keyCode === 13 || event.keyCode === 32) {
                event.preventDefault();
    
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
            }   
        });
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
    // * anchor nav scrollTo
    // *
    // *
    
    // get browser width
    let browserWidth = window.innerWidth;
    let anchorBox = document.querySelector('.js-anchor-nav');
    let anchorBoxSticky = document.querySelector('.js-anchor-sticky');

    // check if anchor modul is set to sticky
    if (anchorBoxSticky !== null) {
        window.addEventListener('scroll', function(e) {
            // get pos from anchorbox
            let pos = Math.round(anchorBox.getBoundingClientRect().y);

            if (pos <= 0) {
                anchorBox.classList.add("stick");
            } else {
                anchorBox.classList.remove("stick");
            }
        });
    }

    // check if anchor modul is set
    if (anchorBox !== null) {
        // needed for sticky anchor to calculate offsetTop
        anchorBoxHeight = anchorBox.offsetHeight;

        if (browserWidth > 1280) {
            anchorBoxHeight = anchorBoxHeight;
        } else {
            anchorBoxHeight = 0;
        }

        const anchors = document.querySelectorAll(".js-scrollTo"); 
        
        for (const anchor of anchors) {
            anchor.addEventListener("click", clickHandler);
        }

        function clickHandler(e) {
            e.preventDefault();
        
            let href = this.getAttribute("href").split("#");
            let newAnchor = "#my" + href[1]
            let offsetTop = document.querySelector(newAnchor).offsetTop - anchorBoxHeight;

            scroll({
                top: offsetTop,
                behavior: "smooth"
            });
        }
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * modal window
    // *
    // *

    let modalWindow = document.querySelector(".js-modal");
    let modalOpen = document.querySelector(".js-modal-btn-open");
    let modalClose = document.querySelector(".js-modal-btn-close");
    
    /* onclick show modal window */
    if (modalOpen !== null) {
        modalOpen.onclick = function() {
            modalWindow.style.display = "block";
            modalClose.focus();
        }
    }
    
    /* close modal window */
    if (modalClose !== null) {
        modalClose.onclick = function() {
            modalWindow.style.display = "none";
        }
    }
    
    /* clicks anywhere outside close modal window */
    window.onclick = function(event) {
        if (event.target == modalWindow) {
            modalWindow.style.display = "none";
        }
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * accordion open every tab
    // *
    // *

    const title = document.querySelectorAll(".js-accordion-title");

    if (title !== null) {
        for (var i=0; i < title.length; i++) {
            title[i].onclick = function(e) {
                this.classList.toggle("open");
                this.nextElementSibling.classList.toggle("open");

                let expanded = this.getAttribute('aria-expanded');
        
                if (expanded == 'false') {
                    this.setAttribute('aria-expanded', 'true')
                } else {
                    this.setAttribute('aria-expanded', 'false')
                }
            }
            title[i].addEventListener("keydown", function (event) {
                if (event.keyCode === 13 || event.keyCode === 32) {
        
                    event.preventDefault();
        
                    this.classList.toggle("open");
                    this.nextElementSibling.classList.toggle("open");
        
                    let expanded = this.getAttribute('aria-expanded');
        
                    if (expanded == 'false') {
                        this.setAttribute('aria-expanded', 'true')
                    } else {
                        this.setAttribute('aria-expanded', 'false')
                    }
                }
            });
        }
    }


    
    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * open external links in new tab
    // *
    // *

    function externalLinks() {
        for(let links = document.getElementsByTagName("a"), number = 0; number < links.length; number++) {
            let currentLink = links[number];
            
            // check if link has same hostname. if not add target blank.
            currentLink.getAttribute("href") && currentLink.hostname !== location.hostname && (currentLink.target = "_blank")
        }
    };
    
    externalLinks();




    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * simpleParallax
    // *
    // *

    let parallaxImages = document.getElementsByClassName('js-parallax');
    new simpleParallax(parallaxImages, {
        delay: 1,
        scale: 1.25
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * aos
    // *
    // *

    AOS.init({
        offset: 100,
        duration: 700,
        delay: 100,
        disable: 'mobile',
        easing: 'ease-out-back'
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * ImageCompare
    // *
    // *

    const viewers = document.querySelectorAll(".image-compare");
    const options = {
        controlColor: "#00594E",
        controlShadow: false,
        addCircle: true,
        addCircleBlur: true
    };

    viewers.forEach((element) => {
        let view = new ImageCompare(element, options).mount();
    });

});
